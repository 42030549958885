import { createStore } from "framework7";
import data from "./pages/tours/blumenthal.json";

const store = createStore({
  state: {
    POIs: [],
  },
  actions: {},
  getters: {
    POIs: () => data.POIs,
  },
});

export default store;
