import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const SEO = ({
               applicationName,
               description,
               faviconPng,
               faviconSvg,
               geoPlaceName,
               geoPosition,
               geoRegion,
               image,
               imageAlt,
               siteName,
               subject,
               themeColor,
               title,
               url,
             }) => (
  <Helmet>
    <title>{title}</title>

    <meta name="description" content={description} />
    <meta name="application-name" content={applicationName} />
    <meta name="subject" content={subject} />

    <meta name="theme-color" content={themeColor} />

    {/* For IE 10 and below */}
    {/* Place favicon.ico in the root directory - no tag necessary */}
    {/* Icon in the highest resolution we need it for */}
    <link type="image/x-icon" rel="icon" sizes="196x196" href={faviconPng} />
    {/* Apple Touch Icon (reuse 192px icon.png) */}
    <link rel="apple-touch-icon" href={faviconPng} />
    {/* Safari Pinned Tab Icon */}
    <link rel="mask-icon" href={faviconSvg} color={themeColor} />
    <meta name="msapplication-TileColor" content={themeColor} />
    <meta name="msapplication-TileImage" content={url + faviconPng} />
    <meta name="msapplication-tooltip" content={applicationName} />

    {/* latitude, longitude */}
    <meta name="ICBM" content={geoPosition.join(", ")} />

    {/* latitude; longitude */}
    <meta name="geo.position" content={geoPosition.join(";")} />
    <meta name="geo.placename" content={geoPlaceName} />
    {/* Country code (ISO 3166-1): mandatory, state code (ISO 3166-2): optional; eg. content="US" / content="US-NY" */}
    <meta name="geo.region" content={geoRegion} />

    <meta property="og:url" content={url} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:image" content={url + image} />
    <meta property="og:image:alt" content={imageAlt} />
    <meta property="og:description" content={description} />
    <meta property="og:site_name" content={siteName} />
    <meta property="og:locale" content="de_DE" />
    <meta property="op:markup_version" content="v1.0" />

    <meta name="twitter:card" content="summary" />
    {/* <meta name="twitter:site" content="@site_account" /> */}
    {/* <meta name="twitter:creator" content="@individual_account" /> */}
    <meta name="twitter:url" content={url} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={url + image} />
    <meta name="twitter:image:alt" content={imageAlt} />

    {/* <link rel="author" href="" /> */}
    {/* <link rel="publisher" href="" /> */}
    <meta itemProp="name" content={title} />
    <meta itemProp="description" content={description} />
    <meta itemProp="image" content={url + image} />

    <link rel="canonical" href={url} />
    {/* <meta property="fb:article_style" content="myarticlestyle" /> */}
  </Helmet>
);

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  applicationName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  faviconPng: PropTypes.string.isRequired,
  faviconSvg: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  geoPosition: PropTypes.arrayOf(PropTypes.number).isRequired, // [latitude, longitude]
  geoPlaceName: PropTypes.string.isRequired,
  geoRegion: PropTypes.string.isRequired, // Country Subdivision Code
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
};

export default SEO;
