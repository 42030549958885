import { App, View } from "framework7-react";
import { useRef } from "react";
import routes from "./Routes";
import store from "./Store";

import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/roboto-condensed/300.css";
import "@fontsource/roboto-condensed/400.css";

import SEO from "../../components/SEO";
// import PropTypes from "prop-types";
import FaviconPng from "./assets/img/blumenthal/favicons/favicon-196x196.png";
import FaviconSvg from "./assets/img/blumenthal/logos/blumenthal.svg";
import StartImage from "./assets/img/blumenthal/unterfuehrung.webp";
import consoleArt from "../../components/ConsoleArt";

const Erleben = () => {
  const f7params = {
    name: "erleben.app",
    theme: "auto",
    id: "app.erleben.blumenthal",
    store,
    routes,
  };

  const ViewParams = {
    browserHistory: true,
    browserHistorySeparator: "",
    browserHistoryRoot: process.env.URL,
    browserHistoryInitialMatch: true,
    browserHistoryOnLoad: false,
    masterDetailBreakpoint: 1024,
    main: true,
    animate: false,
  };

  const SEOParams = {
    title: "Erinnerung an Blumenthal",
    applicationName: "Erinnerung an Blumenthal",
    description:
      "Diese WebApp stellt die Erinnerungsorte der Zeche General Blumenthal 1, 2 und 6 auf der Kunstmeile Recklinghausen vor, einem Rad- und Wanderweg zwischen Friedrich-Ebert-Straße im Westen und Hellbach im Osten.",
    subject: "Tourismus",
    faviconPng: FaviconPng,
    faviconSvg: FaviconSvg,
    themeColor: "#ab283c",
    geoPosition: [51.6051298993948, 7.21094598208706], // [latitude, longitude]
    geoPlaceName: "Recklinghausen",
    geoRegion: "DE-NW", // Country Subdivision Code
    url: "https://blumenthal.erleben.app",
    image: StartImage,
    imageAlt: "Photo von der Unterführung",
    siteName: "Erinnerung an Blumenthal",
  };

  const topRef = useRef();
  const scrollRef = useRef();

  consoleArt();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <App {...f7params}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SEO {...SEOParams} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <View {...ViewParams} scrollRef={scrollRef} topRef={topRef} />
    </App>
  );
};

export default Erleben;
